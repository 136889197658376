<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="d-flex flex-column" style="height: 85vh">
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit a background
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div class="flex-grow overflow-y-scroll">
        <v-card-text class="app-regular-font">
          Upload a photo or choose an image that captures your interests or
          personality.
        </v-card-text>
        <v-card-text
          class="d-flex justify-center flex-column"
          style="font-family:'Poppins-Medium';color: #1e1e1e"
        >
          Upload a photo
          <div class="d-flex justify-center mt-2">
            <editable-background
              :image="profile.bgImage"
              :onImageSelected="onImageSelected"
              ref="editableBg"
            />
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column">
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Add a custom image
          </div>
          <div
            v-for="(img, index) in defaultImages"
            :key="img"
            class="mt-2"
            @click="chooseDefaultImage(index)"
            :class="{ selected: index == choosed }"
            style="position: relative"
          >
            <v-img :src="img" contain />
            <v-avatar
              color="#b5e539"
              size="30"
              style="position: absolute; top: 10px; right: 10px;"
              v-if="index == choosed"
            >
              <v-icon>mdi-check</v-icon>
            </v-avatar>
          </div>
        </v-card-text>
      </div>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions class="pb-6 mt-3 d-flex flex-row justify-center">
        <v-btn
          color="#0A5B8A"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableBackground from "@/components/EditableBackground.vue";
export default {
  components: { EditableBackground },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      code: "",
      name: "",
      message: null,
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      selectedImage: null,
      defaultImages: [
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/1.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/2.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/3.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/4.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/5.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/6.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/7.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/8.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/9.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/10.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/11.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/12.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/13.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/14.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/15.jpg",
        "https://weallvolunteer.s3.us-east-2.amazonaws.com/default_background/16.jpg",
      ],
      profile: {},
      choosed: -1,
    };
  },
  methods: {
    ...mapActions("auth", {
      updateBackground: "updateBackground",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (this.choosed == -1 && !this.selectedImage) {
        this.message = "Please upload or choose an image.";
        return;
      }
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      } else if (this.choosed != -1) {
        formData.append("backgroundIndex", this.choosed);
      }
      this.loading = true;
      this.updateBackground(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.choosed = -1;
      this.message = null;
    },
    chooseDefaultImage(index) {
      this.choosed = index;
      this.selectedImage = null;
      this.message = null;
      this.$refs.editableBg.clearSelected();
    },
  },
  computed: {
    ...mapState("auth", {
      myProfile: "profile",
    }),
  },
  watch: {
    myProfile(newValue) {
      this.profile = { ...newValue };
    },
  },
  mounted() {
    this.profile = { ...this.myProfile };
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    padding-top: 17px;
    padding-bottom: 17px;
    height: 100%;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}

.selected {
  border-width: 2px;
  border-color: blue;
  border-style: solid;
}
</style>
